import styled from "styled-components"
import Button from "../buttons/Button"
import { useEffect, useState } from "react"
import { useShopContext } from "../../hooks/useShopContext"

const StyledShopWidget = styled.div`
font-size:18px;
`
const Line = styled.div`
width:100%;height:1px;background:black;
margin:20px 0px;
`
const Subheader = styled.p`
color:grey;margin-bottom:30px;
a{color:grey;}
span{color:grey;}
a:hover{color:black;}
`
const Links = styled.ul`
margin:40px 0 0 0 ;padding:0px;
li{
  list-style:none;
  display:inline-block; 
  margin:0px 10px 10px 0px;
  button:hover{background:black;color:white;}
}
`
const Images = styled.div`
width:100%;height:200px;overflow-x:scroll;margin-bottom:20px;
ul{
  position:relative;height:100%;width:max-content;padding:0px;overflow-x:scroll;
  img{height:100%;margin-right:10px;}
}
`
const ShopWidget = () => {
  const { shopWidgetData, shopDispatch} : any = useShopContext()
  const [ shopData, setShopData ] = useState<any>()
  useEffect(() => {
    if(shopWidgetData.isOpen === true){
      setShopData(shopWidgetData.shopData)
    }
  },[shopWidgetData])

  const handleClose = () => {shopDispatch({type : "close" })}

  return(
    <StyledShopWidget>
      <header>
        <h4>{ shopData && shopData.name}</h4>
        <img src="./assets/cross.svg" alt="" onClick={handleClose} />
      </header>
      <Line />
      {shopData &&
        <Subheader>
          { shopData.location && <span>Dirección: {shopData.location[0]}</span> }
          <br />
          { shopData.phone.length > 0 && shopData.phone[0] !== ""  && <span>tel:</span> }
          { shopData.phone && shopData.phone.map((phone : any) => {
            const phoneLink = phone.replace(" ","").replace("(","").replace(")","")
            return <a key={phone} href={`tel:${phoneLink}`}> {phone}</a>
          })}
        </Subheader>
      }
      {shopData && shopData.images[0] && 
        <Images>
          <ul>
            { shopData.images.map((image: any, key: any) => {
              return <img key={key} src={`./imgs/shops/${image}`} /> })}
          </ul>
        </Images>
      }
      {shopData && shopData.description && <p>{shopData.description}</p>}
      {shopData && 
        <Links>
          { shopData.facebook && <li><a href={`https://facebook.com/${shopData.facebook}`}><Button size="16">Facebook</Button></a></li>}
          { shopData.instagram && <li><a href={`https://instagram.com/${shopData.instagram}`}><Button size="16">Instagram</Button></a></li>}
          { shopData.website && <li><a href={`https://${shopData.website}`}><Button size="16">Sitio Web</Button></a></li>}
          { shopData.email && shopData.email.map((email : any) => {
            if(email !== ""){
              return <li key={email}><a href={`mailto:${email}`}><Button size="16">Email</Button></a></li>
            }
          })}
        </Links>
      }
    </StyledShopWidget>
  )
}
export default ShopWidget
