import styled from "styled-components"

const StyledFooter = styled.footer`
position:relative;
padding:60px;margin-top:300px;height:75vh;
display:grid;grid-template-columns:repeat(4,1fr);grid-gap:30px;
font-size:20px;
hr{position:absolute;top:0px;width:calc(100% - 120px);left:60px;border:none;height:1px;background:#000;}
p{grid-column:span 2;}
ul{margin:0px;padding:0px;}
li{list-style:none;margin-bottom:20px; span{opacity:.4}}
@media(max-width:800px){
  display:block;
  padding:20px;
  height:100vh;
  margin-top:200px;
  hr{left:20px;width:calc(100% - 40px);}
  p{margin-bottom:60px;margin-top:60px;}
}
`

const Footer = ({footerRef} : any) => {
    return(
        <StyledFooter ref={footerRef}>
            <hr />
            <p>
                Mapa Faro José Ignacio
                <br />
                2023 - 2024
            </p>
            <ul>
                <li>
                    <span>Logística y Desarrollo:</span>
                    <br />
                    <a href="mailto:nanilanza@gmail.com">Nani Lanza</a>
                </li>
                <li>
                    <span>Ilustraciones:</span>
                    <br />
                    <a href="https://www.behance.net/losdibujitosdepablo">Pablo Ferreira</a>
                </li>
                <li>
                    <span>Diseño y Desarrollo Web:</span>
                    <br />
                    <a href="mailto:santiagopm36@gmail.com">Santiago Perdomo</a>
                </li>
            </ul>
            <ul>
                <li>
                    <span>Tel/Wpp:</span>
                    <br />
                    <a href="tel:+59895139914">(+598) 95 139 914</a>
                </li>
            </ul>
        </StyledFooter>
    )
}
export default Footer
