import { createContext, useReducer } from 'react'

export const CategoriesContext = createContext<object>({})

type State = {
  categoriesWidget: Categories
}
type Categories = {
  isOpen : boolean,
  isFiltering : boolean,
  selectedCategory : string
}
type Action = {
  type : string,
  payload : any
}

const categoriesReducer= (state : State, action : Action) => {
  const { type, payload } : any = action;
  switch(type){
    case "open":
      return{
        categoriesWidget: {
          ...state.categoriesWidget,
          isOpen : true
        }
      }
    case "close":
      return{
        categoriesWidget: {
          ...state.categoriesWidget,
          isOpen: false
        }
      }
    case "filter":
      return{
        categoriesWidget: {
          ...state.categoriesWidget,
          isFiltering: true,
          selectedCategory : payload
        }
      }
    case "unfilter":
      return{
        categoriesWidget: {
          ...state.categoriesWidget,
          isFiltering: false,
          selectedCategory: ""
        }
      }
    default:
      return state
  }
}

const initialCategories : State = { categoriesWidget: {
    isOpen: false,
    isFiltering : false,
    selectedCategory : ""
  }
}
interface categoriesContextProviderInterface { children : React.ReactNode }

export const CategoriesContextProvider = ({ children } : categoriesContextProviderInterface) => {
  const [state, dispatch] = useReducer(categoriesReducer, initialCategories)
  return(
    <CategoriesContext.Provider value={{...state, dispatch}}>
      {children}
    </CategoriesContext.Provider>
  )
}
