import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './components/pages/Home/Home';
import Locals from './components/pages/Locals/Locals';
import { useEffect, useRef } from 'react'
import { useMapContext } from './hooks/useMapContext';
import { CategoriesContextProvider } from "./contexts/CategoriesContext" 
import { ShopContextProvider } from "./contexts/ShopContext"

import ReactGA from 'react-ga';
const TRACKING_ID = "G-YXPH90K0BK"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

  const footerRef = useRef<any>(null)
  const { dispatch } : any = useMapContext()

  useEffect(() => {
    const getMap = async () => {
      const response = await fetch("./data.json?version=2.0", {
        headers : {
          "Content-Type" : "application/json",
          "Accept" : "application/json",
        }
      })
      const json = await response.json()
      if(response.ok){
        dispatch({type : "set_data", payload : json })
      }
    } 
    getMap()
  },[ dispatch ])

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar footerRef={footerRef && footerRef}/>
          <CategoriesContextProvider>
            <ShopContextProvider>
              <Routes>
                <Route path="" element={<Home />} />
                <Route path="locales" element={<Locals />} />
              </Routes>
            </ShopContextProvider>
          </CategoriesContextProvider>
        <Footer footerRef={footerRef && footerRef} />
      </BrowserRouter>
    </div>
  );
}

export default App;
