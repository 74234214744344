import { useState } from "react"

export const useMap = (mapWrapper : any,map : any) => {
  // limits
  const [ limitX, setLimitX ] = useState<number>(0)
  const [ limitY, setLimitY ] = useState<number>(0)
  const setLimits = () => {
    setLimitX((map.current.offsetWidth - mapWrapper.current.offsetWidth) / 2)
    setLimitY((map.current.offsetHeight - mapWrapper.current.offsetHeight) / 2)
  }
  
  // map interaction
  const [ isDown, setIsDown ] = useState(false)
  const [ prevMouseXPos, setPrevMouseXPos ] = useState<number>()
  const [ prevMouseYPos, setPrevMouseYPos ] = useState<number>()
  let [ xPos, setXPos] = useState<number>(0)
  let [ yPos, setYPos] = useState<number>(0)
  const handleMouseDown = () => { setIsDown(true) }
  const handleMouseUp = () => {
    setIsDown(false)
    setPrevMouseXPos(0)
    setPrevMouseYPos(0)
  }
  const handleMove = (e : any) => {
    if(e.type === "mousemove"){e.preventDefault()}
    if(isDown){
      if(prevMouseXPos && prevMouseYPos){
        const transformX = (e.clientX || e.touches[0].clientX) - prevMouseXPos;
        const transformY = (e.clientY || e.touches[0].clientY) - prevMouseYPos;
        const tempXPos = xPos+=transformX
        const tempYPos = yPos+=transformY
        if(tempXPos >= -limitX && tempXPos <= limitX){ setXPos(tempXPos);}
        if(tempYPos >= -limitY && tempYPos <= limitY){ setYPos(tempYPos);}
        if(e.type === "touchmove"){checkMobileScroll()}
        map.current.style.transform = "translate(calc(-50% + " + tempXPos +"px),calc(-50% + " + tempYPos + "px))"
      }
      setPrevMouseXPos(e.clientX || e.touches[0].clientX);
      setPrevMouseYPos(e.clientY || e.touches[0].clientY);
    }
  }
  const checkMobileScroll = () => {
    const body : any = document.querySelector("body")
    if(yPos > (-limitY + 5) && yPos < limitY -5){
      mapWrapper.current.scrollIntoView({behavior:"smooth"})
      body.classList.add("locked")
    }else{ body.classList.remove("locked") }
  }
  return { setLimits, handleMouseDown, handleMouseUp, handleMove}
}
