import styled from "styled-components"
import { useMapContext } from "../../hooks/useMapContext"
import { useCategoriesContext } from "../../hooks/useCategoriesContext"
import { useState, useEffect } from 'react'
import Button from "../buttons/Button"

const Header = styled.header<{isOpen : boolean}>`
cursor:pointer;
:hover{opacity:.4;}
img{transition:transform .1s ease;transform:rotate(${props => props.isOpen ? "180" : "0"}deg);}
@media(max-width: 800px){span{display:block;margin-top:10px;}}
`
const List = styled.ul`
margin:40px 0px 0px 0px;padding:0;
li{list-style:none;display:inline-block;margin-right:10px;margin-bottom:10px;}
button{display:inline-block;}
@media(max-width: 800px){
  button{font-size:16px;padding:6px 16px;}
}
`
const StyledButton = styled(Button)<{isDisabled : boolean}>`
border:1px solid transparent;
opacity:${props => props.isDisabled ? ".4" : "1"};
:hover{border-color:black;}
`

const CategoriesWidget = () => {
  const { map_data } : any = useMapContext()
  const { categoriesWidget, dispatch } : any = useCategoriesContext()

  const handleToggle = () => {
    if(categoriesWidget.isOpen === true){ dispatch({type:"close"}) }
    else{ dispatch({type:"open"}) }
  }
  const handleFilter = (categoryTitle : string) => {
    if((categoriesWidget.isFiltering === false) || (categoriesWidget.isFiltering === true && categoriesWidget.selectedCategory !== categoryTitle)){
      dispatch({type : "filter", payload: categoryTitle})
    }else if(categoriesWidget.isFiltering === true && categoriesWidget.selectedCategory === categoryTitle){
      dispatch({type: "unfilter"})
    }
    dispatch({type : "close"})
  }
  const handleDisable = (categoryTitle : string) => {
    if(categoriesWidget.isFiltering === false){
      return false
    }else{
      if(categoriesWidget.selectedCategory === categoryTitle){
        return false
      }else{
        return true
      }
    }
  }

  return(
    <div>
      <Header isOpen={categoriesWidget.isOpen} onClick={handleToggle}>
        <h4>Categorías { categoriesWidget.isFiltering && <span>{categoriesWidget.selectedCategory}</span>}</h4>
        <img src="./assets/arrow_down.svg" alt="" />
      </Header>
      {map_data && categoriesWidget.isOpen && 
        <List>
          {map_data.map((category : any, key : number) => {
            return ( 
              <li key={key} onClick={() => handleFilter(category.name)}>
                <StyledButton isDisabled={handleDisable(category.name)} bk={category.color} color={category.textColor} size="18">
                  {category.name}
                </StyledButton>
              </li>
            )
          })}
        </List>
      }
    </div>
  )
}
export default CategoriesWidget
