import styled from "styled-components"

const StyledHistory = styled.div`
padding:60px;display:grid;grid-template-columns:1fr 1fr;grid-gap:30px;
& h3{
    font-size:40px;font-weight:normal;
    span{opacity:.4;}
}
& p{font-size:24px;}
@media(max-width: 800px){
  padding:20px;grid-template-columns:1fr;
  h3{
    font-size:26px;
  }
  p{
    font-size:20px;line-height:26px;
  }
}
`


const History = () => {
    return(
        <StyledHistory>
            <h3>
                José Ignacio
                <br />
                <span>Un poco de historia...</span>
            </h3>
            <p>
              En 1995 se firma el acuerdo de Cartelería entre todos los comerciantes del Faro y nace la idea de las Flechas en donde todos los comercios pudieran estar presentes y así pudiesen indicar su ubicación, evitando la polución visual de los carteles, banners, luminosos, banderas y plotteos que suelen invadir a los balnearios." La idea fue unirnos comercialmente con un concepto visual común".
              <br /><br />
              En 1999 Paula Martini con el apoyo de la Liga de Fomento de José Ignacio y la colaboración de Cecilia Lynch hacen un mapa ilustrado en madera donde se ubicaron todos los comercios y “puntos de interés “de la península.
              <br /><br />
              En 2009 se intenta formar una unión de comerciantes con el fin de sumar una fuerza que respaldara los intereses no sólo de los veraneantes, sino de los trabajadores locales de la zona. De estas reuniones sólo queda la iniciativa de hacer un mapa común con la ubicación y datos de cada comercio. De estas reuniones sólo queda la iniciativa de hacer un mapa común con la ubicación y datos de cada comercio.
              <br /><br />
              En la temporada del 2010 Paula Martini lleva a cabo esta iniciativa inspirándose en el mapa de madera pero sobretodo siguiendo el concepto de identidad comercial común de las flechas.
              <br /><br />
              En el año 2011 se suma Nani Lanza.
              <br /><br />
              A el día de hoy el mapa sigue siendo un soporte para todos los habitantes y visitantes de José Ignacio, la Juanita y aldeanos, conservando su carácter clásico y perpetuando los valores que hasta el día de hoy nos dan tanta identidad.
            </p>
        </StyledHistory>
    )
}
export default History
