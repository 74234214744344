import { useEffect, useRef } from "react"
import styled from "styled-components"
import Button from "../../buttons/Button"
import MapWrapper from "../../Map/MapWrapper"
import History from "../../History/History"
import WidgetContainer from "../../widgets/WidgetContainer"
import Widget from "../../widgets/Widget"
import CategoriesWidget from "../../widgets/CategoriesWidget"

const Main = styled.main`
position:relative;width:100vw;height:100vh;
& img{position:absolute;width:500px;height:auto;left:50%;top:50%;transform:translate(-50%,-50%);}
@media(max-width:900px){
  & img{width:280px;}
}
`
const Title = styled.h1`
position:absolute;bottom:60px;left:60px;font-size:60px;font-weight:normal;
z-index:2;
span{ font-size:40px;display:block;opacity:.4; }
@media(max-width: 800px){
  display:none;
  text-align:center;left:50%;top:50%;bottom:auto;transform:translate(-50%,-50%);font-size:50px;width:100%;
  span{font-size:30px;}
}
`
const ScrollToMapBtn = styled(Button)`
position:absolute;bottom:60px;right:60px;transition:background .2s ease, color .2s ease;
&:hover{background:#000;color:#FFF;}
@media(max-width: 800px){
  bottom:100px;right:auto;left:50%;transform:translateX(-50%);
  font-size:20px;
}
`
const DownloadButton = styled(Button)`
margin:200px auto;display:block;transition:background .2s ease, color .2s ease;
&:hover{
  backgroundblack;
  color:white;
}
@media(max-width: 800px){ font-size:20px; }
`
const MapContainer = styled.div`
width:100vw;height:140vh;background:#E8BFA5;position:relative;
z-index:20;
@media(max-width:600px){ height:80vh; }
`

const Home = () => {

  const mapContainer = useRef<any>(null)
  const scrollToMap = () => { mapContainer.current.scrollIntoView({ behavior : "smooth" }) }

  useEffect(() => { window.scrollTo({top:0}) },[])

  return(
    <div>
      <Main>
        <Title><span>Mapa Faro</span>José Ignacio</Title>
        <ScrollToMapBtn size="24" bk="#FFF" onClick={scrollToMap}>Ir al mapa</ScrollToMapBtn>
        <img src="./imgs/portada.jpg" />
      </Main>
      <MapContainer ref={mapContainer}>
        <MapWrapper />
      </MapContainer>
      <DownloadButton size="24" bk="#E9C1A9"><a href="./imgs/mapa-2024.jpg">Descargar Mapa</a></DownloadButton>
      <History />
    </div>
  )
}
export default Home
