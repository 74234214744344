import styled from 'styled-components';

const StyledWidget = styled.div`
cursor:default;
background:#FFFFFFde;
padding:20px;
border-radius:10px;
width:100%;
margin-bottom:20px;
header{
  position:relative;
  padding-right:100px;
  img{
    cursor:pointer;
    width:30px;
    position:absolute;top:0;right:0;
    :hover{
      opacity:.4;
    }
  }

}
h4{
  font-size:24px;font-weight:normal;
  span {
    font-size:18px;
    opacity:.4;
  }
}
`

interface WidgetInterface {
  element : React.ReactNode
}
const Widget = ({ element } : WidgetInterface) => {
  return(
    <StyledWidget>
      {element}
    </StyledWidget>
  )
}
export default Widget
