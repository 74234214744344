import { useEffect, useState } from "react"
import styled from "styled-components"
import { useCategoriesContext } from "../../hooks/useCategoriesContext"
import { useShopContext } from "../../hooks/useShopContext"

const Point = styled.div<{
  textColor : string,bk : string,active : boolean, unActive : boolean, left : string, top : string
}>`
left:${props => props.left}%;
top:${props => props.top}%;
opacity:${props => props.unActive ? ".2" : "1"};
background:${props => props.active ? "#000" : props.bk};
color:${props => props.textColor};

border:1px solid black;
transition:transform .2s ease;
position:absolute;
transform:translate(-50%,-50%);
font-size:14px;
width:25px;height:25px;
border-radius:15px;
display:grid;
align-content:center;
justify-content:center;
z-index:10;
:hover{transform:scale(1.15) translate(-50%,-50%);}
`
interface ShopInterface {
  bk:string,
  category:string,
  shop:any,
  textColor:string,
}

const Shop = ({ bk, textColor, category, shop } : ShopInterface) => {

  const { categoriesWidget, dispatch } : any = useCategoriesContext()
  const { shopWidgetData, shopDispatch } : any = useShopContext()
  const [ isUnactive, setIsUnactive ] = useState(false)
  const [ isActive, setIsActive ] = useState(false)

  const open = () => {
    shopDispatch({
      type:"open",
      payload: shop
    })
    dispatch({type:"close"})
  }

  useEffect(() => {
    // handle unactive
    if(categoriesWidget.isFiltering === true){
      if(categoriesWidget.selectedCategory !== category){ setIsUnactive(true) }
      else{ setIsUnactive(false) }
    }else{ setIsUnactive(false) }
    // handle active
    if(shopWidgetData.isOpen === true){
      if(shopWidgetData.shopData.name === shop.name){ setIsActive(true) }
      else{ setIsActive(false) }
    }else{ setIsActive(false) }
  },[categoriesWidget,shopWidgetData,category,shop.name])

  return(
    <Point onClick={() => open()} left={shop.positions[0]} top={shop.positions[1]} active={isActive} unActive={isUnactive} bk={bk} textColor={textColor}>
      { shop.number }
    </Point>
  )
}
export default Shop
