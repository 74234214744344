import styled from "styled-components"
import Button from "../../buttons/Button"
import { useEffect, useState } from "react"
import { useMapContext } from "../../../hooks/useMapContext"

const StyledLocals = styled.div`
padding:300px 60px 0px;
h3{font-weight:normal;font-size:40px;opacity:.4;}
h2{font-weight:normal;font-size:60px;}
@media(max-width: 800px){
  padding:200px 20px 0px;
  h3{font-size:26px;margin-bottom:10px;}
  h2{font-size:50px;}
}
`
const Layout = styled.div`
font-size:24px;margin-top:100px;display:grid;grid-template-columns:2fr 1fr;grid-gap:30px;
@media(max-width: 800px){
  font-size:20px;grid-template-columns:1fr;grid-gap:0px;
}
`
const List = styled.ul`
padding:0px;margin:0;
height:fit-content;
display:grid;grid-template-columns:1fr 1fr;grid-column-gap:30px;grid-row-gap:0px;
li{list-style:none;}
@media(max-width: 800px){
  grid-template-columns:1fr;grid-gap:0px;margin-bottom:100px;
}
`
const ListItem = styled.li<{
  color: string
}>`
display:grid; grid-template-columns:50px 1fr; align-items: center; padding: 4px 0px;
span{
display:flex; align-items:center; place-content:center; width: 40px; height: 40px; border: 1px solid black; border-radius:100px;
background-color: ${props => props.color && props.color};
color: ${props => props.color === "#58156D" ? "white" : "black"};
}
`
const Aside = styled.aside`
p{margin-bottom:20px;}
`

const Locals = () => {

  const {map_data} : any = useMapContext()
  const [ shops, setShops ] = useState<Array<string>>()

  useEffect(() => {
    window.scrollTo({top:0})
    if(map_data){
      let tempShops : any = []
      map_data.forEach((category : any) => {
        category.shops && category.shops.forEach((shop : any) => {
          tempShops.push({
            number: shop.number,
            name: shop.name,
            color: category.color
          })
        })
      })
      setShops(tempShops)
    }
  },[map_data])

  return(
    <StyledLocals>
      <h3>Mapa Faro José Ignacio</h3>
      <h2>Listado de locales</h2>
      <Layout>
        <List>
          { shops && shops.map((shop : any) => {
            return (
              <ListItem key={shop.number} color={shop.color}>
                <span>{ shop.number }</span>
                {shop.name}
              </ListItem>
            )
          })}
        </List>
        <Aside>
          <p>¿Tenés un comercio y querés agregarlo al mapa?</p>
          <Button bk="#FFFFFF" size="18"><a href="mailto:nanilanza@gmail.com">Contactanos</a></Button>
        </Aside>
      </Layout>
    </StyledLocals>
  )
}
export default Locals
