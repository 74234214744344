import styled from "styled-components";

export const StyledMap = styled.div`
cursor:pointer;
width:100vw;height:100dvh;overflow:hidden;position:sticky;top:0px;
&::-webkit-scrollbar{display:none;}
-ms-overflow-style: none;
scrollbar-width: none;
`
export const MapContainer = styled.div`
position:absolute;width:fit-content;height:fit-content;
left:50%;top:50%;transform:translate(-50%,-50%);
`
export const Jpg = styled.img`
position:relative;width:auto;height:180vh;
`
export const Shop = styled.div`
position:absolute;left:64.5%;top:17.4%;transform:translate(-50%,-50%);
width:40px;height:40px;
background:red;
`
