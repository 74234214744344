import { ShopContext } from "../contexts/ShopContext";
import { useContext } from "react";

export const useShopContext = () => {
  const context = useContext(ShopContext)
  if(!context){
    throw Error("useShopContext must be used inside ShopContextProvider")
  }
  return context
}
