import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { MapContextProvider } from "./contexts/MapContext"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MapContextProvider>
      <App />
    </MapContextProvider>
  </React.StrictMode>
);
