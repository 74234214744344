import { useMapContext } from "../../hooks/useMapContext"
import { useShopContext } from "../../hooks/useShopContext"
import WidgetContainer from "../widgets/WidgetContainer"
import Widget from "../widgets/Widget"
import CategoriesWidget from "../widgets/CategoriesWidget"
import ShopWidget from "../widgets/ShopWidget"
import { StyledMap, MapContainer, Jpg} from "./MapStyles"
import Shop from "./Shop"
import { useRef } from "react"
import { useMap } from "../../hooks/useMap"
const MapWrapper = () => {
  const mapWrapper = useRef<any>(null)
  const map = useRef<any>(null)
  const { setLimits, handleMouseDown, handleMouseUp, handleMove} = useMap(mapWrapper,map)

  const { map_data } : any = useMapContext();
  const { shopWidgetData, } : any = useShopContext()

  return(
    <StyledMap ref={mapWrapper} >
      <MapContainer 
        ref={map}
        onMouseDown={() => handleMouseDown()} onTouchStart={() => handleMouseDown()}
        onMouseLeave={() => handleMouseUp()} onMouseUp={() => handleMouseUp()} onTouchEnd={handleMouseUp}
        onMouseMove={(e) => handleMove(e)} onTouchMove={(e) => handleMove(e)}
      >
        <Jpg src="imgs/mapa-2024.jpg" onLoad={() => setLimits()}/>
        {map_data && map_data.map((category : any) => {
            return category.shops && category.shops.map((shop : any) => {
              return <Shop key={shop.number} bk={category.color} textColor={category.textColor} category={category.name} shop={shop} />
            })
        })}
      </MapContainer>
      <WidgetContainer>
        <Widget element={<CategoriesWidget />} />
        { shopWidgetData.isOpen === true && <Widget element={<ShopWidget />} /> }
      </WidgetContainer>
    </StyledMap>
  )
}
export default MapWrapper
