import { createContext, useReducer } from 'react'

export const ShopContext = createContext<object>({})

type State = {
  shopWidgetData: ShopWidgetData
}
type ShopWidgetData = {
  isOpen : boolean,
  shopData? : any 
}
type Action = {
  type: string,
  payload: any
}

const shopReducer = (state: State, action: Action) => {
  const { type, payload } : any = action;
  switch(type){
    case "open":
      return{
        shopWidgetData: {
          isOpen: true,
          shopData: payload 
        }
      }
    case "close":
      return{
        shopWidgetData : {
          isOpen: false
        }
      }
    default:
      return state
  }
}

const initialShop : State = {
  shopWidgetData: {
    isOpen: false,
  }
}

interface shopContextProviderInterface { children : React.ReactNode}

export const ShopContextProvider = ({children} : shopContextProviderInterface) => {
  const [state,shopDispatch] = useReducer(shopReducer,initialShop)
  return(
    <ShopContext.Provider value={{ ...state, shopDispatch}}>
      { children }
    </ShopContext.Provider>
  )
}



