import React, { createContext, useReducer } from "react"

export const MapContext = createContext<object>({})

type Action = {
    type : string,
    payload : "string"
}

const mapReducer = (state : any, action : Action) => {
    const { type, payload } : any = action;
    switch(type){
        case("set_data"):
            return { map_data : payload }
        default: 
            return state
    }
}

interface MapContextProviderInterface { children : React.ReactNode }

export const MapContextProvider = ({ children } : MapContextProviderInterface) => {
    const [state,dispatch] = useReducer(mapReducer,{});
    return(
        <MapContext.Provider value={{...state, dispatch}}>
            { children }
        </MapContext.Provider>
    )
}
