import styled from "styled-components"

const Container = styled.div`
position:sticky;top:60px;left:60px;
width:610px;max-height:calc(100% - 60px);
overflow-y:scroll;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
&::-webkit-scrollbar{display:none;}
@media(max-width: 800px){
  top:20px;left:20px;
  width:calc(100% - 40px);
}
`
interface WidgetProps {
  children : React.ReactNode
}
const WidgetContainer = ({children} : WidgetProps) => {
  return(
    <Container>
      {children}
    </Container>
  )
}
export default WidgetContainer
