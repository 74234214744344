import styled from "styled-components"

const Button = styled.button<{ size : string, bk? : string, color? : string }>`
background:${props => props.bk ? props.bk : "lightgrey"};
color:${props => props.color ? props.color : "black"};
padding:8px 18px;
border:none;
border-radius:40px;
cursor:pointer;
font-size:${props => props.size && props.size}px;
`
export default Button
