import styled from "styled-components"
import { NavLink } from "react-router-dom"

const Header = styled.header`
z-index:10;width:100vw;position:fixed;top:0px;padding:60px;font-size:24px;
& ul{margin:0px;padding:0px;}
& li{
  list-style:none;display:inline-block;margin-right:30px;cursor:pointer;
  :hover{opacity:.5}
}
& a{padding-bottom:10px;}
& a.active{border-bottom:2px solid}
@media(max-width: 800px){
  text-align:center;
  font-size:20px;
  padding:40px 20px;
  li{margin-right:15px;}
  li:nth-child(3){margin-right:0;}
  a{padding-bottom:6px;}
}
`

const Navbar = ({footerRef } : any) => {

  return(
    <Header>
      <ul>
        <li><NavLink to="">Inicio</NavLink></li>
        <li><NavLink to="locales">Locales</NavLink></li>
        <li onClick={() => footerRef.current.scrollIntoView({behavior:"smooth"})}>Contacto</li>
      </ul>
    </Header>
  )
}
export default Navbar
